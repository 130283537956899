<template>
    <div class="dark:bg-gray-900 bg-gray-50 py-24 text-gray-100 ">
        <div class="lg:container mx-auto">
            <h1 class="text-4xl dark:text-white text-gray-700 mx-3">Новости</h1>
        </div>
        <CategorySelector :items="categories" namespace="news"></CategorySelector>
        <List :items="itemsFiltered"></List>
        <Pagination namespace="news"></Pagination>
    </div>
</template>

<script>
import List from "../../components/NewsList";
import Pagination from "../../components/common/Pagination";
import CategorySelector from "../../components/common/CategorySelector";

import {mapGetters} from "vuex";
import axios from "axios";

export default {
    meta:{
        title:'Новости'
    },
    name: "News",
    data: () => ({
        items: [],
        categories: [],
        pageLocal: 1
    }),
    computed: {
        itemsFiltered() {
            return this.items
        },
        ...mapGetters('news', ['page', 'perPage', 'category']),
        lang() {
            return this.$store.getters.lang
        }
    },
    components: {
        CategorySelector, List, Pagination
    },
    created() {
        this.fetchNews(this.pageLocal)
    },
    mounted() {
        this.$watch((vm) => [vm.category, vm.page, vm.perPage, vm.lang], () => {
            this.fetchNews()
        });
    },
    methods: {
        fetchNews() {

            let payload = {
                page: this.page,
                perPage: this.perPage,
                newsCategory: this.category
            }

            axios.post('https://declarant.by/rest/news', payload)
                .then(res => {
                    this.items = res.data.data.items
                    this.categories = res.data.data.categories
                })

        }
    }
}
</script>

<style scoped>

</style>