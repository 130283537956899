<template>
  <div class="lg:container mx-auto">
    <div class="sm:block lg:flex flex-wrap ">
      <div v-for="item in items" :key="item.id" class=" sm:w-full lg:w-1/3 my-3 dark:text-white text-gray-700">
        <div class="card m-3 p-3 dark:bg-gray-800 bg-white shadow-md dark:shadow-none rounded-md h-full">
          <div class="img-box overflow-hidden">
            <img class="w-full transition duration-700 ease-in-out  object-cover object-top hover:scale-150"
                 :src="item.image" alt="Белтаможсервис">
          </div>
          <div class="text-box mb-2">
            <h3 class="text-lg mt-2 mb-4">
              {{ item.title }}
            </h3>
            <span class="text-gray-400">
            {{ item.created_at }}
            </span>
          </div>
          <router-link :to="{name:'NewsDetail', params:{code:item.code}}"  tag="a" class="inline-flex detail-link">Подробнее
            <img class="ml-2" src="@/assets/img/icons/arrow.svg"  alt="Белтаможсервис">
          </router-link>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "NewsList",
  props: {
    items: {
      required: true,
      type: Array
    }
  }
}
</script>

<style scoped>
.img-box {
  max-height: 250px !important;
}
.img-box img {
  max-height: 250px !important;
}
.img-box img:hover {
  transform: scale(1.2);
}
.detail-link:hover img{
  transition: all;
  transition-duration: .3s;
  transition-timing-function: ease-in-out;
  transform: translateX(10px);
}
</style>